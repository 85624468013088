var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"width":"1024px","centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"编辑账号"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('span',[_vm._v("基本信息")]),_c('a-form-item',{attrs:{"label":"账户名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入账户名称' },
                  { max: 50, message: '最多50个字符' }
                ]
              }]),expression:"['name', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { required: true, message: '请输入账户名称' },\n                  { max: 50, message: '最多50个字符' }\n                ]\n              }]"}]})],1),_c('a-form-item',{attrs:{"label":"运营商种类"}},[_vm._v(" "+_vm._s(_vm.carrierTypeDisplay)+" ")]),_c('a-form-item',{attrs:{"label":"所属省市"}},[_c('a-spin',{attrs:{"spinning":_vm.loadingRegionOption}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:(['region', {
                  rules: [{ required: true, message: '请选择省市' }
                  ]
                }]),expression:"['region', {\n                  rules: [{ required: true, message: '请选择省市' }\n                  ]\n                }]"}],attrs:{"field-names":{ label: 'name', value: 'id', children: 'cities' },"options":_vm.regionOptions,"show-search":{ regionFilter: _vm.regionFilter },"placeholder":"请选择省市"}})],1)],1),_c('a-form-item',{attrs:{"label":"联系人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['contact', {
                normalize: this.$lodash.trim,
                rules: [{ max: 50, message: '最多50个字符' }
                ]
              }]),expression:"['contact', {\n                normalize: this.$lodash.trim,\n                rules: [{ max: 50, message: '最多50个字符' }\n                ]\n              }]"}]})],1),_c('a-form-item',{attrs:{"label":"联系邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {
                normalize: this.$lodash.trim,
                rules: [
                  { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\.)+[a-z]{2,4}$/, message: '联系邮箱格式不正确' } ]
              }]),expression:"['email', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\\.)+[a-z]{2,4}$/, message: '联系邮箱格式不正确' },\n                ]\n              }]"}]})],1),_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_number', {
                normalize: this.$lodash.trim,
                rules: [
                  { pattern: /^1[3-9]\d{9}$/, message: '联系电话格式不正确' } ]
              }]),expression:"['phone_number', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { pattern: /^1[3-9]\\d{9}$/, message: '联系电话格式不正确' },\n                ]\n              }]"}]})],1),_c('a-form-item',{attrs:{"label":"签约主体"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'contracting_party_id',
                { rules: [{ required: true, message: '请选择签约主体' }] } ]),expression:"[\n                'contracting_party_id',\n                { rules: [{ required: true, message: '请选择签约主体' }] },\n              ]"}],attrs:{"loading":_vm.loadingContractingPartyOptions,"show-search":"","filter-option":_vm.filterOption,"placeholder":"请选择"}},_vm._l((_vm.contractingPartyOptions),function(contracting_party){return _c('a-select-option',{key:contracting_party.id,attrs:{"value":contracting_party.id,"disabled":!contracting_party.effective}},[_vm._v(" "+_vm._s(contracting_party.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"短信功能"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_has_sms_channel']),expression:"['is_has_sms_channel']"}],attrs:{"checked":_vm.isHasSmsChannel},on:{"change":_vm.changeIsHasSmsChannel}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHasSmsChannel),expression:"isHasSmsChannel"}]},[_c('a-form-item',{attrs:{"label":"短信接口"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sms_channel_id', {
                  rules: [
                    { required: _vm.isHasSmsChannel, message: '请选择短信接口' }
                  ]
                }]),expression:"['sms_channel_id', {\n                  rules: [\n                    { required: isHasSmsChannel, message: '请选择短信接口' }\n                  ]\n                }]"}],attrs:{"loading":_vm.loadingSmsChannelOptions,"show-search":"","filter-option":_vm.filterOption}},_vm._l((_vm.smsChannelOptions),function(smsChannel){return _c('a-select-option',{key:smsChannel.id,attrs:{"value":smsChannel.id,"disabled":_vm.smsChannelOptionsIsDisabled(smsChannel)}},[_vm._v(" "+_vm._s(smsChannel.name)+" ")])}),1)],1)],1),_c('a-divider'),_c('span',[_vm._v("登录信息")]),_c('a-form-item',{attrs:{"label":"运营商平台URL"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['carrier_url', {
                normalize: this.$lodash.trim,
                rules: [{ max: 200, message: '最多200个字符' }
                ]
              }]),expression:"['carrier_url', {\n                normalize: this.$lodash.trim,\n                rules: [{ max: 200, message: '最多200个字符' }\n                ]\n              }]"}]})],1),_c('a-form-item',{attrs:{"label":"运营商平台账号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['carrier_account', {
                normalize: this.$lodash.trim,
                rules: [{ max: 50, message: '最多50个字符' }
                ]
              }]),expression:"['carrier_account', {\n                normalize: this.$lodash.trim,\n                rules: [{ max: 50, message: '最多50个字符' }\n                ]\n              }]"}]})],1),_c('a-form-item',{attrs:{"label":"运营商平台密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['carrier_password', {
                normalize: this.$lodash.trim,
                rules: [{ max: 50, message: '最多50个字符' }
                ]
              }]),expression:"['carrier_password', {\n                normalize: this.$lodash.trim,\n                rules: [{ max: 50, message: '最多50个字符' }\n                ]\n              }]"}]})],1),_c('a-divider'),_c('span',[_vm._v("其他信息")]),_c('a-form-item',{attrs:{"label":"计费周期","required":true}},[_c('a-space',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['charge_day', {
                  rules: [{ required: true, message: '请选择计费周期' }
                  ]
                }]),expression:"['charge_day', {\n                  rules: [{ required: true, message: '请选择计费周期' }\n                  ]\n                }]"}],staticStyle:{"width":"305px"},attrs:{"placeholder":"请选择计费周期"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v(" 1 ")]),_c('a-select-option',{attrs:{"value":27}},[_vm._v(" 27 ")])],1),_c('span',[_vm._v("号")])],1)],1),_c('a-form-item',{attrs:{"label":"普通卡停机保号时长","required":true}},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['ordinary_stopped_months', {
                  rules: [
                    { required: true, message: '请输入普通卡停机保号时长' },
                    { type: 'integer', message: '请输入 1 - 10000000 之间的整数' }
                  ]
                }]),expression:"['ordinary_stopped_months', {\n                  rules: [\n                    { required: true, message: '请输入普通卡停机保号时长' },\n                    { type: 'integer', message: '请输入 1 - 10000000 之间的整数' }\n                  ]\n                }]"}],staticStyle:{"width":"305px"},attrs:{"min":1,"max":10000000,"placeholder":"平台设置普通流量卡的停机保号时长"}}),_c('span',[_vm._v("月")])],1)],1),_c('a-form-item',{attrs:{"label":"NB卡停机保号时长","required":true}},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['nb_stopped_months', {
                  rules: [
                    { required: true, message: '请输入NB卡停机保号时长' },
                    { type: 'integer', message: '请输入 0 - 10000000 之间的整数' }]
                }]),expression:"['nb_stopped_months', {\n                  rules: [\n                    { required: true, message: '请输入NB卡停机保号时长' },\n                    { type: 'integer', message: '请输入 0 - 10000000 之间的整数' }]\n                }]"}],staticStyle:{"width":"305px"},attrs:{"min":0,"max":10000000,"placeholder":"平台设置NB卡的停机保号时长"}}),_c('span',[_vm._v("月")])],1)],1),_c('a-form-item',{attrs:{"label":"提请销卡日期","required":true}},[_c('a-space',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['request_cancel_card_day', {
                  rules: [{ required: true, message: '请选择提请销卡日期' }]
                }]),expression:"['request_cancel_card_day', {\n                  rules: [{ required: true, message: '请选择提请销卡日期' }]\n                }]"}],staticStyle:{"width":"305px"},attrs:{"placeholder":"请选择提请销卡日期"}},_vm._l((28),function(index){return _c('a-select-option',{key:index,attrs:{"value":index}},[_vm._v(" "+_vm._s(index)+" ")])}),1),_c('span',[_vm._v("号")])],1)],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
                rules: [
                  { max: 200, message: '最多200个字符' } ]
              }]),expression:"['remark', {\n                rules: [\n                  { max: 200, message: '最多200个字符' },\n                ]\n              }]"}],attrs:{"auto-size":{ minRows: 3, maxRows: 5 }}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('span',[_vm._v("接口信息")]),_c('a-form-item',{attrs:{"label":"API类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['supplier_api_type', {
                rules: [{ required: true, message: '请选择接口类型' }
                ]
              }]),expression:"['supplier_api_type', {\n                rules: [{ required: true, message: '请选择接口类型' }\n                ]\n              }]"}],attrs:{"disabled":"disabled"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v(" 运营商API ")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v(" 第三方API ")]),_c('a-select-option',{attrs:{"value":0}},[_vm._v(" 无API ")])],1)],1),(_vm.carrierType === 'china_mobile' && _vm.isCarrierApiType)?_c('div',[_c('ChinaMobileApiAccountForm',{attrs:{"china-mobile-api-account-form":_vm.apiAccount}})],1):(_vm.carrierType === 'china_unicom' && _vm.isCarrierApiType)?_c('div',[_c('ChinaUnicomApiAccountForm',{attrs:{"china-unicom-api-account-form":_vm.apiAccount}})],1):(_vm.carrierType === 'china_telecom' && _vm.isCarrierApiType)?_c('div',[_c('ChinaTelecomApiAccountForm',{attrs:{"china-telecom-api-account-form":_vm.apiAccount}})],1):(_vm.carrierType === 'china_broadnet' && _vm.isCarrierApiType)?_c('div',[_c('ChinaBroadnetApiAccountForm',{attrs:{"china-broadnet-api-account-form":_vm.apiAccount}})],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }